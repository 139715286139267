import React from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/shared/Layout';
// import { Helmet } from 'react-helmet';

// import '../css/index.css'; // add some style if you want!

interface BlogProps {
  data: any;
}
const BlogIndex: React.FC<BlogProps> = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <div className="container mx-auto">
        <div className="blog-posts">
          {posts
            .filter((post) => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              return (
                <div className="blog-post-preview" key={post.id}>
                  <h1>
                    <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                  </h1>
                  <h2>{post.frontmatter.date}</h2>
                  <p>{post.excerpt}</p>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;

export default BlogIndex;
